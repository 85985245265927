.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevents horizontal scrolling */
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.services {
  /* Add your styles here */
  padding: 20px 0;
  background-color: #f8f8f8; /* Example background color */
  text-align: center;
}

.services .container h2 {
  /* Add your styles here */
  font-size: 2rem;
}

#result {
  /* Styles for the randomized service */
  font-weight: bold;
}

.services {
  
}

.text-x-xs {
  font-size:0.6rem;
  font-weight:bold;
  color:#fff;
  font-family: 'Space Mono', monospace;
}

#result {
  display: inline-block;
  transition: transform 0.1s ease-in-out;
}

/* Animation keyframes for spinning */
@keyframes spin {
  from { transform: rotateY(0); }
  to { transform: rotateY(360deg); }
}

/* Apply the animation to the #result element when it's updating */
#result.updating {
  animation: spin 0.1s ease-in-out;
}


.carousel {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory; /* Ensures snapping behavior on the x-axis */
}

.carousel-item {
  flex: none; /* Prevents flexbox from stretching the items */
  scroll-snap-align: center; /* Aligns items to the center of the scroll container */
  width: 100%; /* Each item takes full width of the container */
}
